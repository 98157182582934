<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">광고구분</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>광고유무<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Radio
              :dataList="'alarm_ad_register' | getSelectDataList"
              :value.sync="viewModel.model.isMarketing"
              @update:value="viewModel.onUpdateIsMarketing()"/>
            <p class="desc_info">💡 광고인 경우, 마케팅/광고성 정보 수신 대상에게만 발송됩니다</p>
          </td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">타겟설정</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
        <col style="width:190px"/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>발송타겟<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td colspan="2">
            <Radio
              :dataList="'alarm_send_type' | getSelectDataList"
              :value.sync="viewModel.model.sendType"
              :isDisabled="viewModel.isModify"/>
            <p class="desc_info">💡 전체 발송은 추후에 오픈됩니다</p>
            <p class="desc_info">💡 수정이 불가능합니다</p>
          </td>
        </tr>
        <tr v-if="viewModel.model.sendType === 'EXCEL'">
          <th>발송대상 퍼핀유저ID<br>엑셀 업로드<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td v-if="viewModel.isModify" colspan="2">
            <a class="link_tbl tc_link" :href="viewModel.model.targetPath" target="_blank">{{ viewModel.model.targetPath }}</a>
            <p class="desc_info">💡 업로드한 엑셀 다운로드</p>
          </td>
          <td v-else colspan="2">
            <FileUpload
              :dataList.sync="viewModel.model.targetExcel"
              :canMultiple="false"
              :acceptList="['csv']"
              :noticeArray="['페이 ID가 아닌 서비스 ID입니다. 구분에 유의하시길 바랍니다. ']"
              :saveTrigger="targetExcelSaveTrigger"
              :isSaved.sync="targetExcelIsSaved"/>
          </td>
        </tr>
        <tr v-if="viewModel.model.sendType === 'TEST'">
          <th>발송 유저ID 목록</th>
          <td v-if="!viewModel.isModify" >
            <div
              class="group_form"
              v-for="(targetUserId,index) in viewModel.model.targetUserList" :key="`targetUserId${index}`">
              <Input
                placeholder="발송 유저ID를 입력해주세요"
                valueType="numberComma"
                :isBlockUnValueType="true"
                :isError="isErrorTargetUser(index)"
                :value.sync="viewModel.model.targetUserList[index]"/>
              <Button
                btnSize="small"
                btnStyle="fourthly_border"
                text="삭제"
                @onClickBtn="viewModel.onDeleteTargetUser(index)"/>
            </div>
            <div class="group_form">
              <Button
                btnSize="small"
                btnStyle="secondary_border"
                text="발송 유저 추가"
                @onClickBtn="viewModel.onAddTargetUser()">
                <IconSvg
                  iconName="add"
                  :size="12"
                  iconColor="#555"
                  iconDarkColor="DEFAULT_ICON_DARK"/>
              </Button>
            </div>
            <p class="desc_info">💡 중복 및 빈칸 등 유효하지 않은 값은 자동으로 제거되어 등록됩니다.</p>
          </td>
          <td :colspan="viewModel.isModify ? 2 : 1">
            <span v-if="!viewModel.isModify">등록한 발송 유저 ID 목록</span>
            <ul v-for="(cleanItem ,index) in targetUserListClean" :key="`cleanTargetUserId${index}`" class="list_td">
              <li>{{ cleanItem }}</li>
            </ul>
          </td>
        </tr>
        <tr>
          <th>플랫폼<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td colspan="2">
            <Selectbox
              :dataList="'alarm_platform' | getSelectDataList"
              :value.sync="viewModel.model.platform"
              maxWidth="300px" />
          </td>
        </tr>
        <tr>
          <th>회원구분<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td colspan="2">
            <Selectbox
              :dataList="'user_target' | getSelectDataList"
              :value.sync="viewModel.model.targetUserRole"
              maxWidth="300px" />
          </td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">발송설정</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th
            v-if="viewModel.isModify">스케쥴 분할된 유저ID 범위</th>
          <th v-else>스케쥴 분할 유저수<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <div
              v-if="viewModel.isModify"
              class="group_form">
              <span v-if="viewModel.model.startRange ||  viewModel.model.endRange">{{ viewModel.model.startRange }} ~ {{ viewModel.model.endRange }}</span>
              <span v-else>-</span>
            </div>
            <div v-else class="group_form">
              <Input
                placeholder="스케쥴 분할 유저수를 입력해주세요"
                :value.sync="viewModel.model.divider"
                valueType="numberComma"
                :isBlockUnValueType="true"
                maxWidth="300px"/>
              <span class="txt_tbl">명</span>
            </div>
            <p class="desc_info">💡 수정이 불가능합니다</p>
          </td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">알림 이미지</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>알림 이미지</th>
          <td>
            <FileUpload
              :dataList.sync="viewModel.model.imageList"
              :noticeArray="['모바일 전용으로 용량을 줄여 등록해주세요']"
              :canMultiple="false"
              :acceptList="['jpg','jpeg','png']"
              sizeType="FIT"
              :saveTrigger="imageSaveTrigger"
              :isSaved.sync="imageIsSaved"/>
          </td>
        </tr>
      </template>
    </TableView>

    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">퍼핀 앱내 알림</strong>
        </div>
        <div class="area_right">
          <SwitchCheck
            :value.sync="viewModel.model.noti_usable"
            :isSwitchLeft="false"
            text="앱내 알림 발송" />
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>앱내 알림 타이틀<IconSvg v-if="viewModel.model.noti_usable" iconName="essential" :size="8" iconColor="#F04848" /><span class="txt_info">(채널 구분)</span></th>
          <td>
            <Selectbox
              :dataList="'alarm_chanel' | getSelectDataList"
              thumnailKey="imgChanel"
              :value.sync="viewModel.model.noti_group"
              :isDisabled="!viewModel.model.noti_usable"
              maxWidth="300px" />
          </td>
        </tr>
        <tr>
          <th>앱내 알림 본문<IconSvg v-if="viewModel.model.noti_usable" iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Textarea
              placeholder="알림 본문을 입력해주세요"
              :max="400"
              :isCount="true"
              :value.sync="viewModel.model.noti_contents"
              :isDisabled="!viewModel.model.noti_usable"
              :isError="notiContentsError"
              errorDesc="광고인 경우 본문 가장 앞에 (광고)를 기재해 주세요">
            </Textarea>
            <!-- <p class="desc_info">💡 글자수 : 5자 입력</p> -->
            <p class="desc_info">💡 {name}은 받는사람 이름을 표시하는 치환자입니다</p>
          </td>
        </tr>
        <tr>
          <th>앱내 알림 랜딩 URL<IconSvg v-if="viewModel.model.noti_buttonTitle" iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Input
              placeholder="랜딩 URL을 입력해주세요"
              :value.sync="viewModel.model.noti_scheme"
              :isDisabled="!viewModel.model.noti_usable"/>
            <p class="desc_info">💡 별도의 URL이 지정되지 않은 경우, 알림에 링크 버튼이 노출되지 않습니다.</p>
          </td>
        </tr>
        <tr>
          <th>랜딩 버튼명<IconSvg v-if="viewModel.model.noti_scheme" iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Input
              placeholder="랜딩 버튼명을 입력해주세요"
              :value.sync="viewModel.model.noti_buttonTitle"
              :isDisabled="!viewModel.model.noti_usable"/>
            <p class="desc_info">💡 랜딩 URL을 지정하신 경우, 버튼명도 지정해야 합니다.</p>
          </td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">디바이스 푸시</strong>
        </div>
        <div class="area_right">
          <SwitchCheck
            :value.sync="viewModel.model.push_usable"
            :isSwitchLeft="false"
            text="푸시 발송" />
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>푸시 타이틀<IconSvg v-if="viewModel.model.push_usable" iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Input
              placeholder="푸시 타이틀을 입력해주세요"
              :isDisabled="!viewModel.model.push_usable"
              :value.sync="viewModel.model.push_title"/>
          </td>
        </tr>
        <tr>
          <th>푸시 본문<IconSvg v-if="viewModel.model.push_usable" iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Textarea
              placeholder="푸시 본문을 입력해주세요"
              :isDisabled="!viewModel.model.push_usable"
              :value.sync="viewModel.model.push_contents"
              :isError="pushContentsError"
              errorDesc="광고인 경우 본문 가장 앞에 (광고)를 기재해 주세요">
            </Textarea>
          </td>
        </tr>
        <tr>
          <th>푸시 랜딩</th>
          <td>
            <Input
              placeholder="푸시 랜딩을 입력해주세요"
              :isDisabled="!viewModel.model.push_usable"
              :value.sync="viewModel.model.push_scheme">
            </Input>
            <p class="desc_info">💡 사용자가 푸시를 터치하면 앱내 알림에서 지정한 경로로 이동합니다. 지정한 경로가 없는 경우 레몬트리 앱이 실행됩니다.</p>
          </td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">발송일정</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>예약 일시<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <DateTimePicker
              :value.sync="viewModel.model.sendAt"
              :minDate="new Date() | convertDateFormat('YYYY-MM-DD HH:mm:ss')"
              :minHour="viewModel.model.isMarketing ? 8 : null"
              :maxHour="viewModel.model.isMarketing ? 20 : null"/>
            <p v-if="viewModel.model.isMarketing" class="desc_info">💡 광고 메세지는 오전 8시부터 오후 9시까지만 보낼 수 있습니다.</p>
          </td>
        </tr>
      </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="취소"
          routeName="APP_ALARM_LIST"
          :routeQuery="{ status : viewModel.model.tabStatus, pageSize : $route.query.pageSize, page : $route.query.page }"/>
      </div>
      <div class="area_right">
        <template v-if="!viewModel.isModify">
          <Button
            btnSize="medium"
            btnStyle="primary"
            text="등록"
            :disabled="isVaild"
            @onClickBtn="onClickComplete()"/> 
          <!-- viewModel.onClickCompelete -->
        </template>
        <template v-else-if="viewModel.model.status != 'UNREGISTERED'">
          <Button
            btnSize="medium"
            btnStyle="fourthly"
            text="발송예약 취소"
            @onClickBtn="viewModel.onCancel()"/>
          <Button
            btnSize="medium"
            btnStyle="primary"
            text="수정"
            :disabled="isVaild"
            @onClickBtn="onClickComplete()"/>
        </template>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Radio from '@lemontree-ai/lemontree-admin-common-front/components/common/radio/Radio';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import FileUpload from '@lemontree-ai/lemontree-admin-common-front/components/common/file/FileUpload';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';
import SwitchCheck from '@lemontree-ai/lemontree-admin-common-front/components/common/switch/SwitchCheck';
import DateTimePicker from '@lemontree-ai/lemontree-admin-common-front/components/common/datePicker/DateTimePicker';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// viewModel
import AlarmDetailViewModel from '@/views/app/alarm/viewModel/AlarmDetailViewModel'
// mixin
import FileSaveMixin from '@lemontree-ai/lemontree-admin-common-front/mixins/FileSaveMixin'

export default {
  name:'AlarmRegister',
  mixins:[FileSaveMixin],
  components:{
    PageWithLayout,
    TableView,
    IconSvg,
    Radio,
    Selectbox,
    Input,
    FileUpload,
    Textarea,
    SwitchCheck,
    DateTimePicker,
    Sticky,
    Button,
  },
  beforeMount(){
    const id = this.$route.params.id;
    // const status = this.$route.params.status;
    const copyId = this.$route.params.copyId;
    this.viewModel.init(id,copyId);
    this.viewModel.isModify = this.$route.meta.isModify;
  },
  data(){
    return{
      viewModel: new AlarmDetailViewModel(),
      targetExcelSaveTrigger: false,
      targetExcelIsSaved: false,
      imageSaveTrigger: false,
      imageIsSaved: false,
    }
  },
  computed:{
    notiContentsError(){
      return this.viewModel.model.isMarketing && this.viewModel.model.noti_contents.indexOf('(광고)') != 0;
    },
    pushContentsError(){
      return this.viewModel.model.isMarketing && this.viewModel.model.push_contents.indexOf('(광고)') != 0;
    },
    isVaild(){
      return this.viewModel.validatedResult()
    },
    targetUserListClean(){
      let targetUserList = this.viewModel.model.targetUserList.filter(item => item != '').filter(item => Number(String(item).replace(',',''))).map(item => {
        return Number(String(item).replace(',',''))
      }) ;
      const set = new Set(targetUserList);
      const uniqueArr = [...set];
      return uniqueArr;
    }
  },
  watch: {
    targetExcelIsSaved() {
      if (this.targetExcelIsSaved) {
        this.checkFileUpload();
      }
    },
    imageIsSaved() {
      if (this.imageIsSaved) {
        this.checkFileUpload();
      }
    },
  },
  methods:{
    isErrorTargetUser(index){
      const value = this.viewModel.model.targetUserList[index];
      const valueString = String(value);
      const valueNumber = value ? Number(valueString.replace(',','')) : null;
      const targetUserListString = this.viewModel.model.targetUserList.map(item => String(item));
      const diffArray = targetUserListString.filter(item => item === valueString);
      const isDiffIndex = diffArray.length > 1;
      return !valueString || !valueNumber || isDiffIndex
    },
    onClickComplete() {
      const isTargetExcelUpload = Boolean(
        this.viewModel.model.targetExcel.length > 0 &&
          this.viewModel.model.targetExcel[0].file,
      );
      const isImageUpload = Boolean(
        this.viewModel.model.imageList.length > 0 &&
          this.viewModel.model.imageList[0].file,
      );

      this.targetExcelSaveTrigger = isTargetExcelUpload;
      this.imageSaveTrigger = isImageUpload;

      if (!isTargetExcelUpload && !isImageUpload) {
        this.viewModel.onCompleteFileSave();
      }
    },
    checkFileUpload() {
      var fileVaild = true;
      if (this.targetExcelSaveTrigger && !this.targetExcelIsSaved)
        fileVaild = false;
      if (this.imageSaveTrigger && !this.imageIsSaved)
        fileVaild = false;
      if (fileVaild) {
        this.viewModel.onCompleteFileSave();
        console.log('onCompleteFileSave in checkFileUpload')
      }
    },
  }
}
</script>
<style scoped>
.tbl_view tbody td .link_tbl{word-break:break-all}

.list_td li{line-height:22px}
.list_td li:before{display:inline-block;margin:9px 8px 9px 0;width:4px;height:4px;border-radius:100%;background-color:#555;vertical-align:top;content:''}
</style>